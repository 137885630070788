import { ensureBaseDomain, isRunningBaseDomain } from "@xweb/core-utils/src/util/baseDomain";
import { getUnversionedPath } from "../paths";
/**
 * Update the window with global variables that will prepare for calculating resource trees.
 *
 * @param uris The requested uris
 * @param linearResources The list of linear resources
 * @param globals The globals
 */
export function updateGlobals(uris, linearResources, globals) {
    globals = populateInputUriVersions(uris, linearResources, globals);
    globals = populateLinearDepsTree(globals, linearResources);
    globals = ensureBaseDomainGlobals(globals);
    updateBags(globals, window);
}
/**
 * The response globals may not have versions for the requested uris in the DEPS_VERSION_MAP,
 * so populate those by looking at the linearResources, removing the version from those uris,
 * and adding them to the DEPS_VERSION_MAP, if any uris are still missing then assume they are
 * mapped to the last linear resource (e.g. because it was in a group).
 *
 * @param uris The requested uris to add
 * @param linearResources The response list of linear resources
 * @param globals The globals
 * @returns The updated globals with version maps.
 */
export function populateInputUriVersions(uris, linearResources, globals) {
    const DEPS_VERSION_MAP = globals.DEPS_VERSION_MAP ? { ...globals.DEPS_VERSION_MAP } : {};
    const lastResource = linearResources[linearResources.length - 1];
    linearResources.forEach((versioned) => {
        const uri = getUnversionedPath(versioned);
        if (!DEPS_VERSION_MAP[uri]) {
            DEPS_VERSION_MAP[uri] = versioned;
        }
    });
    uris.forEach((uri) => {
        if (!DEPS_VERSION_MAP[uri]) {
            DEPS_VERSION_MAP[uri] = lastResource || uri;
        }
    });
    return {
        ...globals,
        DEPS_VERSION_MAP
    };
}
/**
 * Linear files are the resources returned in which DEPS_TREE is not populated. These resources are expected
 * to load in a particular order. For example, files [A, B, C] need to run in this order, so we will populate
 * a fake DEPS_TREE in which A=[A], B=[B,A], and C=[A,B,C]. This ensures that the files will execute in this
 * order.
 *
 * @param globals The globals
 * @param uris The linear files (already versioned)
 * @return The updated globals object
 */
export function populateLinearDepsTree(globals, uris) {
    const deps = [];
    const DEPS_TREE = globals.DEPS_TREE ? { ...globals.DEPS_TREE } : {};
    filterIgnoredDependencies(uris).forEach((uri) => {
        deps.push(uri);
        if (!DEPS_TREE[uri]) {
            // put a copy of this deps array into the DEPS_TREE
            DEPS_TREE[uri] = deps.concat();
        }
    });
    return { ...globals, DEPS_TREE };
}
// These files are "incorrectly" being returned by `/smrf.xhtml` because every XHTML page will return them
// not because they are actual dependencies of the requested JS file(s).
const IGNORE_DEPENDENCY = {};
[
    "/ui/extlib/XMLHttpRequest/XMLHttpRequest.js",
    "/ui/perflog/js/perflog.js",
    "/verp/ui/perflog-lib/resources/perflog-lib.min.js"
    // Stored in a map for quick lookup if the URI is blacklisted
].forEach((uri) => IGNORE_DEPENDENCY[uri] = true);
/**
 * Filter out any of the blacklisted URIs, which are not actual dependencies.
 *
 * @param uris The list of uris
 * @return The list of filtered uris
 */
export function filterIgnoredDependencies(uris) {
    return uris.filter((uri) => !IGNORE_DEPENDENCY[getUnversionedPath(uri)]);
}
/**
 * Update the globals for base domain.
 *
 * @param globals The globals
 * @return a globals object, or a copy with updated values
 */
export function ensureBaseDomainGlobals(globals) {
    if (isRunningBaseDomain()) {
        return globals;
    }
    const output = { ...globals };
    ['IMAGES', 'RESOURCES', 'DEPS_GROUP', 'DEPS_TREE', 'DEPS_VERSION_MAP'].forEach((k) => {
        output[k] = transformValues(globals[k], ensureBaseDomainUris);
    });
    output.DEPS_TREE = transformKeys(output.DEPS_TREE, ensureBaseDomainUris);
    return output;
}
/**
 * Apply something from one object to another, but in a non-destructive way where
 * properties in the from object are not overwritten.
 *
 * @param from The object to update
 * @param to The object to copy from
 */
export function updateBags(from, to) {
    Object.keys(from).forEach((key) => {
        const current = to[key];
        const value = from[key];
        if (!current) {
            to[key] = from[key];
        }
        else if (typeof current === 'object' && value) {
            Object.keys(value).forEach((mapKey) => {
                if (!current[mapKey]) {
                    current[mapKey] = value[mapKey];
                }
            });
        }
    });
    return to;
}
/**
 * Transform a uri, or array of uris, to be in base domain.
 *
 * @param input A string or array of strings
 * @return The transformed uris or uri
 */
export function ensureBaseDomainUris(input) {
    if (Array.isArray(input)) {
        return input.map(ensureBaseDomainUris);
    }
    return ensureBaseDomain(input);
}
/**
 * Transform a map's values by creating a new map with the values going through the transform function.
 *
 * @param input The input
 * @param transform The transform function
 * @return A new map with transformed values
 */
export function transformValues(input, transform) {
    if (!input) {
        return input;
    }
    const result = {};
    Object.keys(input).forEach((k) => {
        result[k] = transform(input[k]);
    });
    return result;
}
/**
 * Transform a map's keys by creating a new map with the keys going through the transform function.
 *
 * @param input The input
 * @param transform The transform function
 * @return A new map with transformed keys
 */
export function transformKeys(input, transform) {
    if (!input) {
        return input;
    }
    const result = {};
    Object.keys(input).forEach((k) => {
        result[transform(k)] = input[k];
    });
    return result;
}
