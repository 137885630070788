// NOTE: "ASProxy" stands for "Ajax Service Proxy"
/**
 * Check if the uri is an ASProxy file.
 *
 * @param uri The uri
 * @return true if the uri is an ASProxy file
 */
export function isASProxy(uri) {
    return /\/ajaxservice\/.*ASProxy(?:|_[^\/]+)\.js(?:|\?.*)$/.test(uri);
}
/**
 * Check if ASProxy files are required or not.
 *
 * @return true if the ASProxy is requird
 */
export async function isASProxyRequired() {
    return isASProxyRequiredSync();
}
/**
 * Check if ASProxy files are required or not (synchronously).
 *
 * @return true if the ASProxy is requird
 */
export function isASProxyRequiredSync() {
    // UPDATE: After updates to AjaxService in b2211, the ASProxy files are never required
    return false;
}
/**
 * Used for testing purpose.
 */
export function resetASProxy() {
}
