import { ensureBaseDomain } from "@xweb/core-utils/src/util/baseDomain";
import { GLOBAL } from "./globals";
/**
 * Convert a uri to be unversioned.
 *
 * @param src A versioned path
 * @return The unversioned path
 */
export function getUnversionedPath(src) {
    const path = (/^https?:\/\/[^/]*(\/.*)$/.exec(src) || [])[1] || src;
    let parts = /^(?:\/verp\/vmod_[^/]*|)(.*)(?:_dev-snapshot|_[A-Za-z\d+\-=]{32,})\.(css|js)$/.exec(path);
    if (parts) {
        return `${parts[1]}.${parts[2]}`;
    }
    parts = /^(\/ui\/\w+\/\w+)_[\da-f][^/]*(\/.*)\.(css|js)$/.exec(path);
    if (parts) {
        return `${parts[1]}${parts[2]}.${parts[3]}`;
    }
    parts = /^\/verp\/vmod_[^/]*(\/ui\/[^/]*\/resources)_[^/]*(\/.*)/.exec(path);
    if (parts) {
        return parts.slice(1).join("");
    }
    return path;
}
/**
 * Get the include id from the script src attribute.
 *
 * @param src The script src attribute.
 * @return the include id, versioned url without domain or query params
 */
export function getIncludeId(src) {
    return (/(?:https?:|)(?:\/\/[^\/]*|)(\/[^/][^?]*)(\?.*|)$/.exec(src) || [])[1];
}
/**
 * Get a versioned uri.
 *
 * @param uri The uri
 * @return The versioned uri
 */
export function getVersionedUri(uri) {
    var _a, _b;
    return ensureBaseDomain(((_a = GLOBAL.DEPS_GROUPING) === null || _a === void 0 ? void 0 : _a[uri]) || ((_b = GLOBAL.DEPS_VERSION_MAP) === null || _b === void 0 ? void 0 : _b[uri]) || uri);
}
/**
 * Normalize a url to account for ajaxservices.
 *
 * @param uri The non-normalized uri
 * @return The normalized uri
 */
export function normalizeUrl(uri) {
    const [part0, part1] = (/^ajaxservice:([^?.]*)(?:\.([^?]*)|)(?:\?.*|)$/.exec(uri) || []).slice(1);
    if (part0) {
        const moduleName = part1 ? part0 : "v4";
        const controllerName = part1 ? part1 : part0;
        return `/ui/${moduleName}/js/ajaxservice/${controllerName}ASProxy.js`;
    }
    return uri;
}
