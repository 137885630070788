import { ensureBaseDomain, isBaseDomain } from "@xweb/core-utils/src/util/baseDomain";
import { dangerouslyEvalScript } from "@xweb/core-utils/src/util/security/dangerouslyEvalScript";
import { dangerouslyIncludeScript } from "@xweb/core-utils/src/util/security/dangerouslyIncludeScript";
import { fetchSync } from "./fetchSync";
/**
 * Include a resource (either css or js file), the uri must already versioned.
 *
 * @param uri The resource uri, uri must already be versioned.
 * @returns A Promise to load that uri
 */
export async function includeResource(uri, scriptAttributes) {
    const ext = getFileExtension(uri);
    if (ext === ".js") {
        return dangerouslyIncludeScript(uri, scriptAttributes);
    }
    if (ext === ".css") {
        return includeStylesheet(uri);
    }
    throw new Error(`Invalid uri: ${uri}`);
}
/**
 * Get just the file extension portion of a uri.
 *
 * @param uri The uri
 * @return the file extension, e.g. ".js" or ".css"
 */
export function getFileExtension(uri) {
    return (/^(?:[^?#]*)(\.[^.?#]+)([?#].*|)$/.exec(uri) || [])[1];
}
/**
 * Include a resource synchronously (if possible).
 *
 * @param uri The uri to include
 */
export function includeResourceSync(uri) {
    if (uri.endsWith(".js")) {
        if (isBaseDomain(uri)) {
            dangerouslyEvalScript(fetchSync(uri));
        }
        else {
            throw new Error("unsafe eval not base domain uri");
        }
    }
    else if (uri.endsWith(".css")) {
        // Issue: CSS does not support synchronous, the content of CSS will have relative URLs
        // which will not work with inline style tags.
        includeStylesheet(uri);
    }
    else {
        throw new Error(`Invalid uri: ${uri}`);
    }
}
/**
 * Include a css file.
 *
 * @param uri The uri to css file, uri must already be versioned.
 * @return A promise to load the stylesheet.
 */
function includeStylesheet(uri) {
    const link = document.createElement("link");
    link.id = uri;
    link.href = ensureBaseDomain(uri);
    link.rel = "stylesheet";
    link.type = "text/css";
    return insert(link);
}
/**
 * Insert a resource element and wait for it to finish loading.
 *
 * @param el The resource element
 * @returns A promise to load the resource
 */
function insert(el) {
    return new Promise((resolve, reject) => {
        el.addEventListener("error", (evt) => reject(new Error(`${evt.type}: script failure`)));
        el.addEventListener("load", () => resolve());
        document.getElementsByTagName("head")[0].appendChild(el);
    });
}
