import { isASProxy, isASProxyRequiredSync } from "../asProxy";
import { getUnversionedPath, getVersionedUri } from "../paths";
export const AJAX_SERVICE_INCLUDES = [
    "/ui/juic/js/GlobalFunctions.js",
    "/ui/ajaxservice/js/engine.js",
    "/ui/ajaxservice/js/AjaxService.js"
];
// Any file that has a dependency but cannot declare it due should be listed here with hard-coded dependencies
// This is common for extlib files where the source code is already minified and does not include SMRF includes
export const HARD_CODED_DEPS = {
    "/ui/ajaxservice/js/AjaxService.js": ["/ui/ajaxservice/js/engine.js", "/ui/juic/js/GlobalFunctions.js"]
};
/**
 * Sometimes uris will require hard-coded dependencies which cannot be expressed as SMRF dependencies.
 * This is common in extlib files.
 *
 * @param uris The input uris
 * @return The output uris with hard coded dependencies added
 */
export function addHardcodedDeps(uris, version) {
    const deps = [];
    const asRequired = isASProxyRequiredSync();
    uris.forEach((uri) => {
        let hcDeps;
        if (isASProxy(uri)) {
            hcDeps = AJAX_SERVICE_INCLUDES;
        }
        else {
            hcDeps = HARD_CODED_DEPS[getUnversionedPath(uri)] || [];
        }
        hcDeps.forEach((dep) => {
            if (deps.indexOf(dep) < 0) {
                deps.push(version ? getVersionedUri(dep) : dep);
            }
        });
        if (!isASProxy(uri) || asRequired) {
            deps.push(uri);
        }
    });
    return deps;
}
