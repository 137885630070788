/**
 * Make a synchronous GET request for a given URI returning the response as string.
 *
 * @param uri The uri
 * @return The string
 */
export function fetchSync(uri) {
    const request = new XMLHttpRequest();
    request.open('GET', uri, false);
    request.send(null);
    if (request.status === 200) {
        return request.responseText;
    }
    throw new Error(`Error: ${request.status}`);
}
