/**
 * Parse some HTML to find resources included inside, and any inline scripts.
 *
 * @param html The html
 * @return The parsed data
 */
export function parseHTML(html) {
    let insideScript = false;
    const linearResources = [];
    const inlineScripts = [];
    html.split(/\r?\n/).forEach(function (line) {
        let parts = /<(?:link|script)[^>]*(?:src|href)=["']([^"']*)["']/.exec(line);
        if (parts) {
            linearResources.push(parts[1]);
        }
        else if (/^<script[^>]*><!--$/.test(line)) {
            insideScript = true;
        }
        else if (line.indexOf("//--></script>") >= 0) {
            insideScript = false;
        }
        else if (/^<script[^>]*>$/.test(line)) {
            insideScript = true;
        }
        else if (line.indexOf("</script>") >= 0) {
            insideScript = false;
        }
        else if (insideScript) {
            line && inlineScripts.push(line);
        }
    });
    if (inlineScripts.length > 0) {
        return {
            linearResources,
            inlineScript: inlineScripts.join("\n")
        };
    }
    return { linearResources };
}
