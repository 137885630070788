import { fetchSync } from "../fetchSync";
import { GLOBAL } from "../globals";
import { parseJavascriptContent } from "./parseJavascriptContent";
const log = (...msg) => console.log("[@xweb/smrf-loader/src/util/bookkeeping/fetchTestData.ts]", ...msg);
/**
 * Fetch the bookkeeping data for unit test files.
 *
 * @param uris The uris
 * @return A promise for the data
 */
export async function fetchTestData(uris) {
    const globals = defaultGlobals();
    const negativeCache = new Set();
    const seen = new Set(uris);
    let round = new Set(seen);
    while (round.size) {
        const current = [...round];
        round = new Set();
        await Promise.all(current.map(async (uri) => {
            const response = await fetch(uri);
            if (response.ok) {
                let jsContent;
                try {
                    jsContent = await response.text();
                }
                catch (e) {
                    negativeCache.add(uri);
                    handleError(uri, e.message);
                }
                if (jsContent) {
                    parseGlobals(uri, jsContent, globals).forEach((uri) => {
                        if (!seen.has(uri)) {
                            seen.add(uri);
                            round.add(uri);
                        }
                    });
                }
            }
            else {
                negativeCache.add(uri);
                handleError(uri, `Error: ${response.status}`);
            }
        }));
    }
    return prepareResult(uris, globals, negativeCache);
}
/**
 * Fetch the bookkeeping data for unit test files synchronously.
 *
 * @param uris The uris
 * @return The data
 */
export function fetchTestDataSync(uris) {
    const globals = defaultGlobals();
    const negativeCache = new Set();
    const seen = new Set(uris);
    let round = new Set(seen);
    while (round.size) {
        const current = [...round];
        round = new Set();
        current.forEach((uri) => {
            let jsContent;
            try {
                jsContent = fetchSync(uri);
            }
            catch (e) {
                negativeCache.add(uri);
                handleError(uri, e.message);
            }
            if (jsContent) {
                parseGlobals(uri, jsContent, globals).forEach((uri) => {
                    if (!seen.has(uri)) {
                        seen.add(uri);
                        round.add(uri);
                    }
                });
            }
        });
    }
    return prepareResult(uris, globals, negativeCache);
}
function handleError(uri, msg) {
    log(`Could not fetch ${uri}: ${msg}`);
}
/**
 * Parse the js content and add to globals.
 *
 * @param uri The uri to the js file
 * @param jsContent The js content
 * @param globals The globals to add to
 * @return The next round of uris
 */
function parseGlobals(uri, jsContent, { DEPS_VERSION_MAP, DEPS_TREE, RESOURCES, IMAGES }) {
    const parsed = parseJavascriptContent(jsContent);
    const { dependencies = [], laterDeps = [], images = [], resources = [] } = parsed;
    DEPS_VERSION_MAP[uri] = uri;
    DEPS_TREE[uri] = [...dependencies, uri];
    resources.forEach((uri) => (RESOURCES[uri] = uri));
    images.forEach((uri) => (IMAGES[uri] = uri));
    const nextRound = [...dependencies, ...laterDeps];
    log(`parseGlobals ${uri}:`, { parsed, nextRound });
    return nextRound;
}
function prepareResult(uris, globals, negativeCache) {
    let result = {
        linearResources: uris.filter((uri) => !negativeCache.has(uri)),
        globals
    };
    const tree = globals.DEPS_TREE;
    const map = globals.DEPS_VERSION_MAP;
    [...negativeCache].forEach((key) => {
        delete tree[key];
        delete map[key];
    });
    Object.keys(tree).forEach((uri) => {
        tree[uri] = tree[uri].filter((uri) => !negativeCache.has(uri));
    });
    result.globals = addBasePath(globals);
    log("result:", result);
    return result;
}
/**
 * In a unit test environment, the path like `/ui/module/js/test.js` can be
 * served from 2 locations:
 *
 * 1) directly from the raw url, returns raw javascript content
 * 2) from the faux "resources/main/webapps" ui5 library location, can return either the same js content
 *    or in the case of local files will return transpiled versions to report coverage.
 *
 * For purposes of parsing the js content, the first url should be used because the second one will not
 * parse correctly for SMRF directives. For execution the second one is used, since that will report
 * unit test coverage.
 *
 * This function is used to add the base path to local URLs to always parse raw content for SMRF directives
 * and always execute transpiled copies to report test coverage.
 *
 * @param globals The globals
 */
export function addBasePath(globals) {
    var _a, _b, _c;
    const base = (_c = (_b = (_a = GLOBAL.sap) === null || _a === void 0 ? void 0 : _a.ui) === null || _b === void 0 ? void 0 : _b.resource) === null || _c === void 0 ? void 0 : _c.call(_b, "main/webapp", "");
    if (base) {
        const tree = globals.DEPS_TREE;
        const map = globals.DEPS_VERSION_MAP;
        const versionTestUri = (uri) => uri.startsWith("/ui/") ? base + uri.substring(1) : uri;
        const vtree = {};
        const vmap = {};
        Object.entries(tree).forEach(([uri, deps]) => {
            vtree[versionTestUri(uri)] = deps.map(versionTestUri);
        });
        Object.keys(map).forEach((uri) => {
            vmap[uri] = versionTestUri(uri);
        });
        return {
            ...globals,
            DEPS_TREE: vtree,
            DEPS_VERSION_MAP: vmap
        };
    }
    return globals;
}
export function defaultGlobals() {
    return {
        DEPS_GROUPING: {},
        DEPS_TREE: {},
        DEPS_VERSION_MAP: {},
        IMAGES: {},
        MSGS: {},
        RESOURCES: {},
        jsSFMessages: {},
        dateFormatSymbols: {
            ampm: ["AM", "PM"],
            firstDayOfWeek: 1,
            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            shortWeekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            singleCharWeekdays: ["S", "M", "T", "W", "T", "F", "S"],
            weekdays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        },
        decimalFormatSymbols: {
            currencySymbol: "¤",
            exponential: "E",
            infinity: "∞",
            nan: "�",
            patternSeparator: ";",
            percent: "%",
            zeroDigit: "0"
        },
        firstDayOfWeek: 1
    };
}
