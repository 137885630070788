import { ensureBaseDomain, isRunningBaseDomain } from "@xweb/core-utils/src/util/baseDomain";
import { dangerouslyEvalScript } from "@xweb/core-utils/src/util/security/dangerouslyEvalScript";
import { fetchSync } from "../fetchSync";
import { GLOBAL } from "../globals";
import { fetchTestData, fetchTestDataSync } from "./fetchTestData";
import { parseHTML } from "./parseHTML";
/**
 * Fetch bookkeeping data for the given uris.
 *
 * @param uris The uris that are missing bookkeeping data.
 * @return The bookeeping data
 */
export async function fetchBookkeepingData(uris) {
    var _a;
    if ((_a = GLOBAL.SMRF) === null || _a === void 0 ? void 0 : _a.isTest) {
        return fetchTestData(uris);
    }
    if (!isRunningBaseDomain()) {
        throw new Error("must be running base domain");
    }
    const response = await fetch(getBookkeepingPayloadURL(uris));
    if (response.ok) {
        return dangerouslyEvalData(parseHTML(await response.text()));
    }
    else {
        throw new Error("Failed: " + response.statusText);
    }
}
/**
 * Fetch bookkeeping data for the given uris synchronously.
 *
 * @param uris The uris that are missing bookkeeping data.
 * @return The bookeeping data
 */
export function fetchBookkeepingDataSync(uris) {
    var _a;
    if ((_a = GLOBAL.SMRF) === null || _a === void 0 ? void 0 : _a.isTest) {
        return fetchTestDataSync(uris);
    }
    if (!isRunningBaseDomain()) {
        throw new Error("must be running base domain");
    }
    return dangerouslyEvalData(parseHTML(fetchSync(getBookkeepingPayloadURL(uris))));
}
/**
 * Get the uri to request a payload of data about uris.
 *
 * @param uris The uris to fetch
 * @returns The uri to fetch payload
 */
export function getBookkeepingPayloadURL(uris) {
    const query = uris.map((uri) => `js%5B%5D=${encodeURIComponent(uri)}`).join("&");
    return ensureBaseDomain(`/xi/ui/commonshell/pages/smrf.xhtml?${query}`);
}
/**
 * Eval the globals from the inline script returned by smrf.xhtml
 *
 * @param safePayload The inline script (must be "safe", it will be evaluated)
 * @return The globals
 */
export function dangerouslyEvalData(safePayload) {
    const ALL_GLOBAL_KEYS = [
        // Keys that are included in the Bookkeeping data
        "DEPS_GROUPING",
        "DEPS_TREE",
        "DEPS_VERSION_MAP",
        "IMAGES",
        "MSGS",
        "jsSFMessages",
        "RESOURCES",
        "dateFormatSymbols",
        "decimalFormatSymbols",
        "firstDayOfWeek"
    ];
    const BLACKLIST_KEYS = ["ajaxSecKey"]; // do not allow overwriting these keys
    const { linearResources, inlineScript } = safePayload;
    const globals = {};
    const current = GLOBAL;
    const backup = {};
    ALL_GLOBAL_KEYS.forEach((k) => {
        globals[k] = current[k];
        // Check if the property is configurable before deleting it
        if (current.hasOwnProperty(k)) {
            const propertyDescriptor = Object.getOwnPropertyDescriptor(current, k);
            if (propertyDescriptor && propertyDescriptor.configurable) {
                delete current[k];
            }
            else {
                current[k] = undefined;
            }
        }
    });
    BLACKLIST_KEYS.forEach((k) => {
        backup[k] = current[k];
    });
    if (inlineScript) {
        dangerouslyEvalScript(inlineScript);
    }
    ALL_GLOBAL_KEYS.forEach((k) => {
        const t = globals[k];
        if (current[k]) {
            globals[k] = current[k];
        }
        else {
            delete globals[k];
        }
        current[k] = t;
    });
    BLACKLIST_KEYS.forEach((k) => {
        current[k] = backup[k];
    });
    return {
        linearResources,
        globals
    };
}
