import { GLOBAL } from "../globals";
/**
 * A formatter, typically defined already by smrf.
 */
export class Formatter {
    format(pattern, ...args) {
        return pattern && args.reduce(function (p, a, i) {
            return p.replace(new RegExp('\\{' + i + '\\}', 'g'), a);
        }, pattern);
    }
}
/**
 * Init a couple of global variables that legacy files depend on.
 */
export function initGlobals() {
    const formatter = new Formatter();
    const format = formatter.format;
    const MSGS = GLOBAL.MSGS || {};
    const o = MSGS;
    if (!o.get) {
        o.get = (key, ...args) => format.call(null, MSGS[key], ...args);
    }
    if (!GLOBAL.MessageFormatter) {
        GLOBAL.MessageFormatter = function () {
            return formatter;
        };
    }
    if (!o.MessageFormatter) {
        o.MessageFormatter = formatter;
    }
    if (!GLOBAL.MSGS) {
        GLOBAL.MSGS = MSGS;
    }
    if (!GLOBAL.jsSFMessages) {
        GLOBAL.jsSFMessages = MSGS;
    }
    if (!GLOBAL.sfMessageFormat) {
        GLOBAL.sfMessageFormat = formatter;
    }
}
