import { createStorage } from "@xweb/core-utils/src/util/Storage";
import { GLOBAL } from "../globals";
/**
 * Get the stored bookkeeping data, if available.
 *
 * @param uris The uris
 * @return The data if stored
 */
export function getStoredData(uris) {
    const stored = getStorage().getItem(getCacheKey(uris));
    if (stored) {
        return stored;
    }
    return undefined;
}
/**
 * Put the stored bookkeeping data, if possible.
 *
 * @param uris The uris
 * @param data The data to store
 */
export function putStoredData(uris, data) {
    const storage = getStorage();
    if (storage.isSupported()) {
        storage.setItem(getCacheKey(uris), data);
    }
}
/**
 * Get the storage object.
 *
 * @return The storage
 */
export function getStorage() {
    return createStorage("sessionStorage", "@xweb/smrf-loader");
}
/**
 * Used for testing.
 */
export function clearStorage() {
    getStorage().clear();
}
/**
 * Get a cache key for the uris.
 *
 * @param uris The uris
 * @return A cache key
 */
export function getCacheKey(uris) {
    var _a;
    const userLocale = (_a = GLOBAL.pageHeaderJsonData) === null || _a === void 0 ? void 0 : _a.userLocale;
    return uris.join(',') + `${userLocale ? ('?lang=' + encodeURIComponent(userLocale)) : ''}`;
}
