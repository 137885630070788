// THIS FILE IS COPIED FROM:
// https://github.wdf.sap.corp/xweb/smrf-tools/blob/master/src/utils/parseJavascriptContent.ts
// Regular Expressions copied from smrf-tools Directives:
// https://github.wdf.sap.corp/bizx/idl-smrf-tools/blob/976689890fb637720f37e03d00c09009e42280f1/smrf-tools/src/main/java/com/successfactors/smrftools/directives
const REGEX = {
    dependencies: /^ *\/\/! include *([^ ]+) *$/g,
    i18nKeys: /MSGS.get\(['"](\w+)|MSGS\.(\w+)|MSGS\[['"](\w+)|rb.getText\(['"](\w+)['"]|['"]\{?i18n\w*>(\w+)\}?['"]/g,
    images: /IMAGES ?\[ *['"](.*?)['"] *\]/g,
    laterDeps: /SMRF.load\(\[ *(.*) *\]/g,
    resources: /RESOURCES ?\[ *['"](.*?)['"] *\]/g
};
/**
 * Parse a Javascript file content into a SMRFDependency by running a series of regular expressions on the content.
 *
 * @param jsContent The Javascript content
 * @return The parsed result, including any SMRF directives
 */
export function parseJavascriptContent(jsContent) {
    const result = {};
    // For each line in the javascript content, loop over all the regular expressions, executing each one
    // to find any group matches, then adding all captured groups to the corresponding array in the result
    jsContent.split(/\r?\n/).forEach((line) => {
        Object.entries(REGEX).forEach(([key, regex]) => {
            const matches = line.matchAll(regex);
            [...matches].forEach((match) => {
                const output = result[key] = result[key] || [];
                let matches = [...match.slice(1).filter(Boolean)];
                if (key === "laterDeps") {
                    matches = matches[0].split(/['", ]+/).map((v) => v.trim()).filter(Boolean);
                }
                if (key === "laterDeps" || key === "dependencies") {
                    matches = matches.map((uri) => normalizeUrl(uri.trim()));
                }
                matches.forEach((value) => {
                    // Only add the value if not already in the output list
                    if (output.indexOf(value) < 0) {
                        output.push(value);
                    }
                });
            });
        });
    });
    return result;
}
/**
 * Normalize a url to account for ajaxservices.
 *
 * @param uri The non-normalized uri
 * @return The normalized uri
 */
export function normalizeUrl(uri) {
    const [part0, part1] = (/^ajaxservice:([^?.]*)(?:\.([^?]*)|)(?:\?.*|)$/.exec(uri) || []).slice(1);
    if (part0) {
        const moduleName = part1 ? part0 : "v4";
        const controllerName = part1 ? part1 : part0;
        return `/ui/${moduleName}/js/ajaxservice/${controllerName}ASProxy.js`;
    }
    return uri;
}
